import { createTheme, ThemeProvider } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import OperatorServiceCenterReevaluationResultPage from '../../organisms/OperatorServiceCenter/OperatorServiceCenterApplicationReevaluationResultPage'
import OperatorServiceCenterBorrowerData from '../../organisms/OperatorServiceCenter/OperatorServiceCenterBorrowerData'
import { OperatorServiceCenterBorrowerReevaluation } from '../../organisms/OperatorServiceCenter/OperatorServiceCenterBorrowerReevaluation'
import OperatorServiceCenterBorrowerRestriction from '../../organisms/OperatorServiceCenter/OperatorServiceCenterBorrowerRestriction'
import OperatorServiceCenterDocumentVerification from '../../organisms/OperatorServiceCenter/OperatorServiceCenterDocumentVerification'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { PhoneLayout } from '../../ui/PhoneLayout'
import { OPERATOR_SERVICE_CENTER_PAGES } from './enums/operator-service-center-pages.enum'

const theme = createTheme({
  palette: {
    primary: {
      main: '#11B048',
    },
  },
})

const OperatorServiceCenter: React.FC = () => {
  const { page } = useTypedSelector(state => state.operatorServiceCenter)

  const renderPage = () => {
    switch (page) {
      case OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE:
        return (
          <Container>
            <OperatorServiceCenterDocumentVerification />
          </Container>
        )
      case OPERATOR_SERVICE_CENTER_PAGES.BORROWER_RESTRICTION_PAGE:
        return (
          <PhoneLayout>
            <OperatorServiceCenterBorrowerRestriction />
          </PhoneLayout>
        )
      case OPERATOR_SERVICE_CENTER_PAGES.BORROWER_PAGE:
        return (
          <PhoneLayout>
            <OperatorServiceCenterBorrowerData />
          </PhoneLayout>
        )
      case OPERATOR_SERVICE_CENTER_PAGES.BORROWER_REEVALUATION_PAGE:
        return (
          <PhoneLayout>
            <OperatorServiceCenterBorrowerReevaluation />
          </PhoneLayout>
        )
      case OPERATOR_SERVICE_CENTER_PAGES.APPLICATION_REEVALUATION_RESULT_PAGE:
        return (
          <PhoneLayout>
            <OperatorServiceCenterReevaluationResultPage />
          </PhoneLayout>
        )
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <>{renderPage()}</>
    </ThemeProvider>
  )
}

export default OperatorServiceCenter

const Container = styled(PhoneLayout).attrs({
  style: {
    padding: 0,
  },
})``
