import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { history } from '../../../App'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'
import { OPERATOR_SERVICE_CENTER_PAGES } from '../../../pages/OperatorServiceCenter/enums/operator-service-center-pages.enum'
import { bffParceirosApiService } from '../../../services/bff'
import { OperatorServiceCenterBorrowerResponse, OperatorServiceCenterHomeInfoResponse } from '../../../services/bff/operatorServiceCenter/dtos/OperatorServiceCenter.dto'
import ApplicationSliceReducer from '../application/application.reducer'
import { APPLICATION_STATUS } from '../application/application.types'
import OperatorServiceCenterSliceReducer, { IFetchBorrowerPayload, ReevaluateBorrowerPayload } from './operator-service-center.reducer'
import { OperatorServiceCenterErrorHandler } from './OperatorServiceCenterErrorHandler'
import { OperatorServiceCenterValidator } from './OperatorServiceCenterValidator'

function* fetchBorrower(action: PayloadAction<IFetchBorrowerPayload>) {
  const { document, storeId } = action.payload

  try {
    yield put(OperatorServiceCenterSliceReducer.actions.setDocument(document))

    const response: OperatorServiceCenterBorrowerResponse = yield bffParceirosApiService.operatorServiceCenter.fetchBorrower(document, storeId)

    yield put(OperatorServiceCenterSliceReducer.actions.fetchBorrowerSuccess(response))

    const borrowerApplicationRestriction = OperatorServiceCenterValidator.validateBorrowerApplicationStatus(response!)
    const borrowerIsDisableToPurchase = OperatorServiceCenterValidator.validateBorrowerPurchaseEligibility(response!)
    const isBorrowerInPreApplication = OperatorServiceCenterValidator.isBorrowerInPreApplication(response)
    const shouldReevaluateApplication = OperatorServiceCenterValidator.shouldReevaluate(response!, storeId)

    if (shouldReevaluateApplication) {
      yield put(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.BORROWER_REEVALUATION_PAGE))
    } else if (isBorrowerInPreApplication) {
      yield put(ApplicationSliceReducer.actions.resetState())
      yield put(ApplicationSliceReducer.actions.fetchPreApplication({ cpf: document }))
      history.push(PagesPathEnum.APPLICATION.replace(':cpf', document))
    } else if (borrowerApplicationRestriction) {
      yield put(OperatorServiceCenterSliceReducer.actions.setBorrowerRestriction(borrowerApplicationRestriction))
      yield put(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.BORROWER_RESTRICTION_PAGE))
    } else if (borrowerIsDisableToPurchase) {
      yield put(OperatorServiceCenterSliceReducer.actions.setBorrowerRestriction(borrowerIsDisableToPurchase))
      yield put(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.BORROWER_RESTRICTION_PAGE))
    } else {
      yield put(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.BORROWER_PAGE))
    }
  } catch (error) {
    yield put(OperatorServiceCenterSliceReducer.actions.fetchBorrowerError(error))

    OperatorServiceCenterErrorHandler.handleError(error, document)
  }
}

function* reevaluateBorrower(action: PayloadAction<ReevaluateBorrowerPayload>) {
  const { borrowerId, storeId, document } = action.payload

  try {
    const reevaluationStatus: APPLICATION_STATUS = yield bffParceirosApiService.applicationEngine.handleReevaluation({ borrowerId, storeId })
    const borrower: OperatorServiceCenterBorrowerResponse = yield bffParceirosApiService.operatorServiceCenter.fetchBorrower(document, storeId)
    yield put(OperatorServiceCenterSliceReducer.actions.reevaluateBorrowerSuccess({ borrower, reevaluationStatus }))

    switch (reevaluationStatus) {
      case APPLICATION_STATUS.APPROVED:
      case APPLICATION_STATUS.DENIED:
        yield put(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.APPLICATION_REEVALUATION_RESULT_PAGE))
      default:
    }
  } catch (error) {
    yield put(OperatorServiceCenterSliceReducer.actions.reevaluateBorrowerError(error))

    OperatorServiceCenterErrorHandler.handleError(error, borrowerId)
  }
}

function* fetchHomePageInfo(action: PayloadAction<undefined>) {
  try {
    const response: OperatorServiceCenterHomeInfoResponse = yield bffParceirosApiService.operatorServiceCenter.fetchHomePageInfo()
    yield put(OperatorServiceCenterSliceReducer.actions.fetchHomePageInfoSuccess(response))
  } catch (error) {
    yield put(OperatorServiceCenterSliceReducer.actions.fetchHomePageInfoError(error))
  }
}

const operatorServiceCenterSaga = [
  takeLatest(OperatorServiceCenterSliceReducer.actions.fetchBorrower, fetchBorrower),
  takeLatest(OperatorServiceCenterSliceReducer.actions.reevaluateBorrower, reevaluateBorrower),
  takeLatest(OperatorServiceCenterSliceReducer.actions.fetchHomePageInfo, fetchHomePageInfo),
]

export default operatorServiceCenterSaga
