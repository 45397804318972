import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../App'
import { ArrowBackButton } from '../../atoms/Buttons/ArrowBackButton'
import { Button } from '../../atoms/Buttons/Button'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'
import { ReactComponent as NewWindowIcon } from '../../common/assets/images/new-window-icon.svg'
import OperatorProfileInformation from '../../molecules/OperatorServiceCenter/operator/OperatorProfileInformation'
import { StoreSelection } from '../../molecules/StoreSelection/StoreSelection'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../services/bff'
import { UserSessionService } from '../../services/userSession/user-session.service'
import { PhoneLayout } from '../../ui/PhoneLayout'
import { green, white } from '../../ui/utils/_colors'
import PhoneUtils from '../../utils/phone.utils'
import { PagesPathEnum } from '../enums/pages-path.enum'

const OperatorConfiguration: React.FC = () => {
  const { isFetchingOperator, operator } = useTypedSelector(state => ({
    isFetchingOperator: state.operator.isFetchingOperator,
    operator: state.operator.operator,
  }))

  const [isRedirectingPage, setIsRedirectingPage] = useState<boolean>(false)

  const dispatch = useDispatch()

  useEffect(() => {
    UserSessionService.fetchOperator(dispatch)
  }, [])

  const obfuscatedPhoneNumber = PhoneUtils.obfuscatePhoneMask(operator?.phoneNumber)

  const handleBackButton = () => {
    window.location.href = PagesPathEnum.OPERATOR_SERVICE_CENTER
  }

  const handleUpdateOperatorDataOnClick = async () => {
    setIsRedirectingPage(true)

    try {
      const updateOperatorDataLink = await bffParceirosApiService.retailersOnboarding.getOperatorsDataValidationLink({ document: operator!.cpf })

      setIsRedirectingPage(false)
      window.open(updateOperatorDataLink.url, '_blank')
    } catch {
      setIsRedirectingPage(false)
      toaster.showErrorToast('Ocorreu um erro, tente novamente.')
    }
  }

  if (isFetchingOperator) return <LoadingSpinner />
  if (isRedirectingPage) return <LoadingSpinner text={'Levando você para um ambiente seguro...'} />

  return (
    <>
      <MainContainer>
        <BackButtonContainer>
          <ArrowBackButton onBackButtonClick={handleBackButton} />
        </BackButtonContainer>

        <ConfigurationContainer>
          <Title>Configurações</Title>

          <StoreConfiguration>
            <Subtitle>Alterar Loja</Subtitle>
            <StoreSelection />
          </StoreConfiguration>

          <Line />

          <OperatorProfile>
            <Subtitle>Dados cadastrais</Subtitle>
            <OperatorProfileInformation title={'Nome'} value={operator?.name} />
            <OperatorProfileInformation title={'Telefone'} value={PhoneUtils.maskPhone(obfuscatedPhoneNumber)} />
            <OperatorProfileInformation title={'E-mail'} value={operator?.email} />
            <OperatorProfileInformation title={'Chave Pix'} value={operator?.pixAccount?.pixKey} />
          </OperatorProfile>
        </ConfigurationContainer>
        <Footer>
          {operator && (
            <Button
              text={'Alterar dados cadastrais'}
              textColor={green.dark2}
              backgroundColor={white.primary}
              border={`1px solid ${green.dark2}`}
              icon={NewWindowIcon}
              onClick={handleUpdateOperatorDataOnClick}
            />
          )}
        </Footer>
      </MainContainer>
    </>
  )
}

export default OperatorConfiguration

const MainContainer = styled(PhoneLayout).attrs({
  style: {
    padding: '24px 16px 0px 16px',
  },
})``

const BackButtonContainer = styled.div``

const Title = styled.text`
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  line-height: 22px;
`

const ConfigurationContainer = styled.div`
  margin-top: 16px;
  text-align: left;
`

const StoreConfiguration = styled.div`
  margin-top: 24px 0px;
`

const OperatorProfile = styled.div`
  margin-top: 24px;
`

const Subtitle = styled.h2`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin: 16px 0px;
`

const Line = styled.hr`
  color: #7f7f7f;
  opacity: 0.3;
  border-bottom-width: 1px;
  width: 100%;
  margin: 24px 0px;
`

const Footer = styled.div`
  margin-top: 8px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  bottom: 16px;
`
