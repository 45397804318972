import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Role } from '../../common/constants'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { IOperator } from '../../services/bff/coordinator/dto/Operators.dto'
import { white } from '../../ui/utils/_colors'
import OperatorServiceCenterHomeCard from './OperatorServiceCenterHomeCard'

enum FilterOptions {
  TODAY = 'Hoje',
  WEEK = 'Semana',
  MONTH = 'Mês',
}
interface OperatorServiceOperatorPerformanceProps {
  operator?: IOperator
}

export const operatorRolesWithDashboardAccess = [Role.PROMOTER, Role.ANALYST]

export const OperatorServiceOperatorPerformance: React.FC<OperatorServiceOperatorPerformanceProps> = (props: OperatorServiceOperatorPerformanceProps) => {
  const { operatorPerformance, isFetchingHomePageInfo } = useTypedSelector(state => ({
    operatorPerformance: state.operatorServiceCenter.operatorPerformance,
    isFetchingHomePageInfo: state.operatorServiceCenter.isFetchingHomePageInfo,
  }))
  const [selectedFilter, setSelectedFilter] = useState<string>(FilterOptions.TODAY)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(OperatorServiceCenterSliceReducer.actions.fetchHomePageInfo())
  }, [])

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter)
  }

  const getFilteredData = () => {
    switch (selectedFilter) {
      case FilterOptions.TODAY:
        return {
          conversions: operatorPerformance?.today?.conversions ?? 0,
          applications: operatorPerformance?.today?.applications ?? 0,
          incentive: operatorPerformance?.today?.incentive ?? 0,
        }
      case FilterOptions.WEEK:
        return {
          conversions: operatorPerformance?.week?.conversions ?? 0,
          applications: operatorPerformance?.week?.applications ?? 0,
          incentive: operatorPerformance?.week?.incentive ?? 0,
        }
      case FilterOptions.MONTH:
        return {
          conversions: operatorPerformance?.month?.conversions ?? 0,
          applications: operatorPerformance?.month?.applications ?? 0,
          incentive: operatorPerformance?.month?.incentive ?? 0,
        }
      default:
        return {
          conversions: 0,
          applications: 0,
          incentive: 0,
        }
    }
  }

  const filteredData = getFilteredData()

  if (!operatorRolesWithDashboardAccess.includes(props?.operator?.role as Role)) {
    return <></>
  }
  return (
    <Container>
      <Title>Seu desempenho</Title>
      <FilterWrapper>
        {Object.values(FilterOptions).map(filter => (
          <FilterButton key={filter} active={selectedFilter === filter} onClick={() => handleFilterClick(filter)}>
            {filter}
          </FilterButton>
        ))}
      </FilterWrapper>

      <CardsWrapper>
        <OperatorServiceCenterHomeCard
          isLoading={isFetchingHomePageInfo}
          text={String(filteredData.applications)}
          title="Clientes cadastrados"
          toolTipText="Clientes aprovados e cadastrados por você."
          placement="top"
        />
        <OperatorServiceCenterHomeCard
          isLoading={isFetchingHomePageInfo}
          text={String(filteredData.conversions)}
          title="Clientes convertidos"
          toolTipText="Clientes que fizeram uma compra após o seu cadastro ou consulta."
          placement="topRight"
        />
      </CardsWrapper>
    </Container>
  )
}
const Container = styled.div`
  background: #f3f7fa;
  padding: 24px 16px;
  height: 100%;
`

const FilterWrapper = styled.div`
  margin: 16px 0px;
`
interface FilterButtonProps {
  active: boolean
}

const FilterButton = styled.button<FilterButtonProps>`
  padding: 6px 12px 6px 12px;
  border-radius: 32px;
  margin: 0px 8px 0px 0px;

  border: 1px solid ${({ active }: FilterButtonProps) => (active ? '#3C4852' : '#E4ECF1')};
  background-color: ${({ active }: FilterButtonProps) => (active ? '#3C4852' : `${white.primary}`)};
  color: ${({ active }: FilterButtonProps) => (active ? 'white' : 'black')};

  font-family: Inter;
  font-size: 14px;
  font-weight: ${({ active }: FilterButtonProps) => (active ? '600' : '400')};
  line-height: 20px;
  text-align: left;

  &:hover {
    background-color: ${({ active }: FilterButtonProps) => (active ? '#3C4852' : '#e0e0e0')};
  }
`
const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px 16px;
`

const Title = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
`
