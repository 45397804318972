import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { history } from '../../App'
import { ArrowBackButton } from '../../atoms/Buttons/ArrowBackButton'
import { Button } from '../../atoms/Buttons/Button'
import { LoadingIcon } from '../../atoms/LoadingIcon/LoadingIcon'
import CardBorrower from '../../molecules/OperatorServiceCenter/CardBorrower'
import CardOffer from '../../molecules/OperatorServiceCenter/CardOffer'
import TxCreditCard from '../../molecules/OperatorServiceCenter/TxCreditCard'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import TransactionalCreditSliceReducer from '../../redux/reducers/tx-credit/tx-credit.reducer'
import { bffParceirosApiService } from '../../services/bff'
import { TransactionalCreditEvaluationResult } from '../../services/bff/transactional-credit/enums/transactional-credit-evaluation-results.enum'
import { TransactionalCreditEvaluationStatus } from '../../services/bff/transactional-credit/enums/transactional-credit-evaluation-status.enum'
import { green, white } from '../../ui/utils/_colors'
import CouponDetails from './CouponDetails'
import { BorrowerData } from './DataLists/BorrowerData'
import { UserAvailableProducts } from './DataLists/UserAvailableProducts'

const OperatorServiceCenterBorrowerData: React.FC = () => {
  const { borrowerData, txCreditEvaluation, isFetchingTransactionalCreditEvaluation } = useTypedSelector(state => ({
    borrowerData: state.operatorServiceCenter.borrower,
    txCreditEvaluation: state.transactionalCredit.transactionalCreditEvaluation,
    isFetchingTransactionalCreditEvaluation: state.transactionalCredit.isFetchingTransactionalCreditEvaluation,
  }))

  const dispatch = useDispatch()

  const store = bffParceirosApiService.coordinator.getStore()

  useEffect(() => {
    return () => {
      dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(
      TransactionalCreditSliceReducer.actions.fetchTransactionalCreditEvaluation({
        cpf: borrowerData!!.borrower.document,
        storeId: store?.id,
        retailerId: store?.retailerId,
        status: TransactionalCreditEvaluationStatus.ACCEPTED,
        results: [TransactionalCreditEvaluationResult.APPROVED, TransactionalCreditEvaluationResult.PARTIALLY_APPROVED],
      })
    )
  }, [])

  const handleBackButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
  }

  const handleBackToHomeButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
    history.push(PagesPathEnum.HOME_PAGE)
  }

  return (
    <>
      <BackButtonContainer>
        <ArrowBackButton onBackButtonClick={handleBackButton} />
      </BackButtonContainer>

      <MainContainer>
        <CardBorrower borrowerName={borrowerData?.borrower?.name} borrowerData={BorrowerData(borrowerData!)} />

        {isFetchingTransactionalCreditEvaluation && <LoadingIcon />}
        {!isFetchingTransactionalCreditEvaluation && txCreditEvaluation && <TxCreditCard availableLimit={borrowerData?.credit.availableLimit ?? 0} evaluation={txCreditEvaluation} />}

        {borrowerData?.coupon ? <CouponDetails coupon={borrowerData?.coupon!} showStoreAlert={false} /> : null}

        <CardOffer
          userAvailableProducts={UserAvailableProducts({
            document: borrowerData!.borrower!.document,
            borrowerId: borrowerData?.borrower?.id,
          })}
        />
      </MainContainer>
      <Footer>
        <Button text={'Sair'} textColor={green.dark2} backgroundColor={white.primary} border={`1px solid ${green.dark2}`} onClick={handleBackToHomeButton} />
      </Footer>
    </>
  )
}

export default OperatorServiceCenterBorrowerData

const BackButtonContainer = styled.div`
  margin: 16px 0px;
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100% - 56px);
  width: 100%;
  padding: 10px 0px;
`
