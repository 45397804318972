import React from 'react'
import styled from 'styled-components'

interface OperatorProfileProps {
  title: string
  value?: string
}

const OperatorProfileInformation = ({ title, value }: OperatorProfileProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 24px;
`

const Title = styled.h6`
  font-family: Inter;
  font-size: 14px;
  margin: 0px 0px 4px 0px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`

const Value = styled.h5`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
`

export default OperatorProfileInformation
