import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { IOperator } from '../../../services/bff/coordinator/dto/Operators.dto'

interface OperatorState {
  operator?: IOperator
  isFetchingOperator: boolean
}

const initialState: OperatorState = {
  operator: undefined,
  isFetchingOperator: false,
}

export interface IFetchOperatorPayload {
  operatorId: string
}

const operatorSliceReducer = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    fetchOperator: (state, action: PayloadAction<IFetchOperatorPayload>) => {
      const { operatorId } = action.payload
      return {
        ...state,
        operatorId,
        isFetchingOperator: true,
      }
    },
    fetchOperatorSuccess: (state, action: PayloadAction<IOperator>) => {
      const operator = action.payload
      return {
        ...state,
        operator,
        isFetchingOperator: false,
      }
    },
    fetchOperatorError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      return {
        ...state,
        error,
        isFetchingOperator: false,
      }
    },
  },
})

export default operatorSliceReducer
